<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback />  {{ $t('MedicineEquipmentExpirationReport') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-select
            v-model.trim="drugCategorySelect"
            :items="drugCategoryList"
            :label="$t('category')"
            outlined
            dense
            :no-data-text="$t('no_information')"
            :item-text="$i18n.locale"
            item-value="value"
            @change="addPayload"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.drug_id`]="{ item }">
          <router-link
            :to="{ name: 'drugDetail', params: { id: item.drug_id_pri } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.drug_id }}
          </router-link>
        </template>
        <template v-slot:[`item.drugorder_expdate`]="{ item }">
          <span
            v-if="item.drugorder_expdate == $t('total')"
            class="font-weight-bold primary--text"
          > {{ item.drugorder_expdate }} </span>
          <span v-else> {{ item.drugorder_expdate }} </span>
        </template>
        <template v-slot:[`item.drugordert_total`]="{ item }">
          <span
            v-if="item.drugorder_expdate == $t('total')"
            class="font-weight-bold primary--text"
          > {{ item.drugordert_total }} </span>
          <span v-else> {{ item.drugordert_total }} </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import Comeback from '../Comeback.vue'
import drugTypeForSearch from '@/@fake-db/data/drugTypeForSearch.json'
import { i18n } from '@/plugins/i18n'
import { reportExpired, removeComma } from '../useExcel'

export default {
  components: {
    Comeback,
  },
  setup() {
    const moment = require('moment')
    const XLSX = require('xlsx')
    const drugCategorySelect = ref('')
    const drugCategoryList = ref(drugTypeForSearch.data)
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('code'), value: 'drug_id', width: 100 },
      { text: i18n.t('group'), value: 'drug_category_name', width: 100 },
      { text: i18n.t('name'), value: 'drug_name', width: 250 },
      { text: i18n.t('lot'), value: 'druglot_name', width: 180 },
      {
        text: i18n.t('expire_date'), value: 'drugorder_expdate', width: 180, align: 'end',
      },
      {
        text: i18n.t('number_expired'), value: 'drugordert_total', width: 120, align: 'end',
      },
    ])
    const options = ref({})
    const exportLoading = ref(false)

    onMounted(() => {
      addPayload()
    })

    const addPayload = () => {
      payload.value = {
        drug_category_id: drugCategorySelect.value,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }

    const searchReport = async payload => {
      loading.value = true
      const { data } = await reportExpired(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const fileName = `${i18n.t(
        'MedicineEquipmentExpirationReport',
      )}  ${moment().format('DD-MM-YYYY')} .xlsx`

      let dataExport = dataTableList.value.map((item, i) => {
        delete item.drug_id_pri

        return { number: i + 1, ...item }
      })
      dataExport = await removeComma(dataExport)

      const Heading = [
        [
          `${i18n.t(
            'MedicineEquipmentExpirationReport',
          )} ${moment().format('DD-MM-YYYY')}`,
        ],
        [
          '#',
          i18n.t('group'),
          i18n.t('code'),
          i18n.t('name'),
          i18n.t('lot'),
          i18n.t('expire_date'),
          i18n.t('number_expired'),
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'drug_category_name',
          'drug_id',
          'drug_name',
          'druglot_name',
          'drugorder_expdate',
          'drugordert_total',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }]
      const wscols = [
        { wch: 6 },
        { wch: 12 },
        { wch: 12 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 12 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('MedicineEquipmentExpirationReport')}`.replace('/', '-'))

      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      drugCategorySelect,
      drugCategoryList,
      payload,
      loading,
      dataTableList,
      columns,
      options,
      exportLoading,
      addPayload,
      searchReport,
      exportExcel,
      mdiFileExcelOutline,
    }
  },
}
</script>
